// router/index.js
import { createRouter, createWebHistory } from "vue-router";
import GameCalendar from "../components/GameCalendar.vue";
import GameDetails from "../components/GameDetails.vue";
import NgdTerms from "../components/NgdTerms.vue";
import UnsubscribeGame from "../components/UnsubscribeGame.vue";
import UnsubscribeConfirmation from "../components/UnsubscribeConfirmation.vue";
import axios from "axios";
import { API_BASE_URL } from "../api/config";

const routes = [
  {
    path: "/",
    name: "GameCalendar",
    component: GameCalendar,
    meta: { title: "New Game Day - Your Ultimate Game Release Calendar" },
  },
  {
    path: "/game/:slug",
    name: "GameDetails",
    component: GameDetails,
    props: true,
    meta: { title: "New Game Day" },
  },
  {
    path: "/terms-and-conditions",
    name: "NgdTerms",
    component: NgdTerms,
    meta: { title: "New Game Day - Terms and Conditions" },
  },
  {
    path: "/unsubscribe",
    name: "UnsubscribeGame",
    component: UnsubscribeGame,
    props: (route) => ({ email: route.query.email }),
  },
  {
    path: "/unsubscribed",
    name: "UnsubscribeConfirmation",
    component: UnsubscribeConfirmation,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // If there's a saved position, use it (like going back with the browser)
    if (savedPosition) {
      return savedPosition;
    } else {
      // Otherwise, scroll to the top of the page
      return { left: 0, top: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  let baseTitle = to.meta.title;

  if (to.name === "GameDetails" && to.params.id) {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/games/${to.params.slug}`);
      const gameName = response.data.data.game.name;
      document.title = `${baseTitle} - ${gameName}`;
    } catch (error) {
      console.error("Failed to fetch game details:", error);
      document.title = baseTitle; // Fallback to base title
    }
  } else {
    document.title = baseTitle;
  }

  next();
});

export default router;
