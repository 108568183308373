<!-- components/GameDetails.vue -->
<template>
  <!-- <HeaderComponent :title="game.name" :subtitle="game.releaseDate"/> -->
  <div v-if="isLoading" class="loading-indicator">
    <div class="spinner"></div>
    <p>Loading game details...</p>
  </div>
  <div v-else-if="game">
    <HeaderComponent :title="game.name" :subtitle="formattedReleaseDate" :bg-image="game.imageUrls.publicImage" />
    <main class="main">
      <!-- Email Reminder Form -->
      <section class="section">
        <div class="container padding-global-margins padding-top-xhuge padding-bottom-huge">
          <div class="flex-row padding-bottom-custom-1">
            <!-- <div class="auto-column">
                    <div class="platform-image-tag"><img src="images/Windows-1.svg" loading="lazy" alt=""></div>
                </div> -->
            <!-- Dynamic platform tags -->
            <div class="auto-column" v-for="platform in game.platforms.split(';')" :key="platform">
              <div class="platform-image-tag">
                <img
                  class="platform-image-tag-img"
                  :src="platformImageMap[platform.trim()]"
                  loading="lazy"
                  :alt="platform"
                />
              </div>
            </div>
          </div>
          <div class="flex-row padding-bottom-medium">
            <div class="_12-columns padding-bottom-small">
              <p class="game-details">
                Publisher: {{ game.publisher }}<br />
                Genres: {{ game.genres.join(", ") }}<br />
                Budget: {{ game.budget }}<br />
                <a :href="game.website" target="_blank">Website</a>
              </p>
            </div>
          </div>
          <div class="flex-row padding-bottom-xlarge">
            <div class="_6-columns _12-mobile-landscape-columns padding-bottom-small">
              <h2 class="padding-bottom-xsmall">Remind Me on Release Date</h2>
              <form class="form" @submit.prevent="submitReminder">
                <div class="display-horizontal-flex padding-bottom-xsmall">
                  <input class="text-field" type="email" v-model="reminderEmail" placeholder="Your email" required />
                  <button
                    class="submit-button"
                    :class="{ disabled: !acceptTerms }"
                    type="submit"
                    :disabled="!acceptTerms"
                  >
                    {{ buttonLabel }}
                  </button>
                </div>
                <label class="checkbox-field">
                  <input class="checkbox" type="checkbox" id="acceptTerms" v-model="acceptTerms" required />
                  <span class="checkbox-label padding-left-xsmall" for="acceptTerms"
                    >I accept the
                    <a href="/terms-and-conditions" target="_blank" class="link">terms and conditions</a></span
                  >
                </label>
              </form>
              <transition name="fade">
                <div v-if="message" :class="messageType === 'error' ? 'error-box' : 'success-box'" class="message-box">
                  {{ message }}
                </div>
              </transition>
            </div>
          </div>
          <div class="flex-row">
            <div class="_9-columns _12-mobile-portrait-columns">
              <p class="paragraph">{{ game.description }}</p>
            </div>
          </div>
        </div>
        <aside id="calendar-wrapper" class="container padding-global-margins"></aside>
      </section>
      <section class="section">
        <div class="container padding-global-margins">
          <div class="flex-row padding-bottom-small">
            <div class="auto-column">
              <h2>Release Date Trailer</h2>
            </div>
          </div>
          <div class="flex-row">
            <div class="_12-columns">
              <!-- <div style="padding-top:56.17021276595745%" class="w-video w-embed">
                        <iframe class="embedly-embed" :src="game.releaseTrailer" width="940" height="528" scrolling="no" allowfullscreen></iframe>
                    </div> -->
              <div class="video-responsive">
                <iframe
                  width="100%"
                  height="auto"
                  :src="game.releaseTrailer"
                  :title="game.name"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="bottom-navbar w-nav">
        <a :href="backLink" class="nav-link-block w-inline-block">
          <div>&lt;&lt; Back to Calendar</div>
        </a>
      </div>
    </main>
  </div>
  <div v-else>
    <p>Game not found.</p>
  </div>
</template>

<script>
import HeaderComponent from "../components/Header.vue";
import axios from "axios";
import { API_BASE_URL } from "../api/config";

export default {
  name: "GameDetails",
  components: {
    HeaderComponent,
  },
  props: ["slug"],
  data() {
    return {
      isLoading: true,
      game: null,
      reminderEmail: "",
      acceptTerms: false,
      message: "", // Message state
      messageType: "", // Message type: 'error' or 'success'
      platformImageMap: {
        xsxs: require("@/assets/images/MicrosoftXboxSeries.webp"),
        ps5: require("@/assets/images/SonyPlaystation5.webp"),
        ps4: require("@/assets/images/SonyPlaystation4.webp"),
        x1: require("@/assets/images/MicrosoftXboxOne.webp"),
        pc: require("@/assets/images/Windows.webp"),
        sw: require("@/assets/images/NintendoSwitch.webp"),
        // steamdeck: require("@/assets/images/Steam_Deck_colored_logo.webp"),
        macos: require("@/assets/images/mac-os.webp"),
        atarivcs: require("@/assets/images/1024px-Atari_VCS_logo.webp"),
        metaquest: require("@/assets/images/Meta_Quest_logo.webp"),
        visionpro: require("@/assets/images/Apple_Vision_Pro.webp"),
        ios: require("@/assets/images/iOS-Logo.webp"),
        android: require("@/assets/images/android-logo-2019.webp"),
        linux: require("@/assets/images/linux-operating-systems-logo.webp"),
      },
    };
  },
  computed: {
    formattedReleaseDate() {
      if (!this.game || !this.game.releaseDate) return "";
      const date = new Date(this.game.releaseDate);
      return new Intl.DateTimeFormat(navigator.language, {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(date);
    },
    buttonLabel() {
      return this.acceptTerms ? "Set Reminder" : "Accept T&Cs";
    },
    backLink() {
      // Retrieve the last calendar page URL from localStorage
      const lastCalendarPage = localStorage.getItem("lastCalendarPage");

      // Fallback to the default calendar page if no URL was stored
      return lastCalendarPage || "/";
    },
  },
  mounted() {
    console.log("GameDetails mounted, slug received:", this.slug); // Ensure this is logging
    console.log("User's locale:", navigator.language);
    this.fetchGame();
    // this.logUserLocation();
  },
  methods: {
    async fetchGame() {
      console.log("Fetching game details for:", this.$route.params.slug);
      this.isLoading = true;
      try {
        const response = await axios.get(`${API_BASE_URL}/api/games/${this.$route.params.slug}`);
        if (response.status !== 200) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const game = response.data.data.game; // Extract the game data from the response
        this.game = game; // Assign the fetched game data to this.game
        console.log("Game details fetched:", this.game);
      } catch (error) {
        console.error("Failed to fetch game details:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async submitReminder() {
      if (!this.acceptTerms) {
        alert("You must accept the terms and conditions.");
        return;
      }
      try {
        const response = await axios.post(`${API_BASE_URL}/api/reminders`, {
          email: this.reminderEmail,
          acceptTerms: this.acceptTerms,
          gameId: this.game._id,
        });

        // If the reminder was set successfully, subscribe the user to the MailerLite newsletter
        await axios.post(
          "https://connect.mailerlite.com/api/subscribers",
          {
            email: this.reminderEmail,
            groups: ["131622667697522131"], // Replace this with the correct group ID
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiOGEwYTgxMDY3YWE5YmRkZDg1NmJhNDhjMWU2ZTUyMDZlNmJjODJkYjlmNWQxNzZiMTkxZGM1NTAyYmM5MWNhY2U0M2RjMjhiODE1YzNjNDEiLCJpYXQiOjE3MjU1MjU0NDMuOTE0NzY4LCJuYmYiOjE3MjU1MjU0NDMuOTE0NzczLCJleHAiOjQ4ODExOTkwNDMuOTA3OTA3LCJzdWIiOiIxMDg2Mzk4Iiwic2NvcGVzIjpbXX0.MtFawkF7_PLhUa1M5LQLnUVA6FXx0Z-WnxmKpSy8HAfMVTq2X1Ne4osWFyikbFEReqKmm5uAzzey0k8auaTm-5hesiekQ3wYz0sS1hD_--qlmywRWICSp7rJdSnz3V1F9qqq0_iqB24awnIoaDAzaldEmfojcJSKL_99TosXL-hWxkMfoMLpqdyrDAsHFO9JxDnqy-10evC_a9Um2J0AURMfI_gryByLaLqUCADF9ITm7wRfYKitA0v6eJbMCHYmt53cMKErRkueV_SFq7o951oGeL9yk4MdkwO0FH1Xlkpxk3xaJmWA5dOAI5Pi3zinIFxto1l8CvAFfk2eF7D5ljs9L_p7B9ZbqjoL-EyRC5mjK-74ciMV0KzgQ1vbHO2dki6Krc0EEKvuJO14172dQCZ2Am_cT9AHgIeE4e7PvX0XekiF4G-vQAWX8017VXTp-LK2yex3IdBBZ-dRc8vl4Qqe3kwRovQJuquRGYtkRnBQLT1hqtoUbv3c_o0S66QJ8JFobUbf_apkDiPQrfQcWtZ1XeKSEoFXxw5OBCOO4VeS0nXfPS86-p0YuymIP0C1-5o2tByjxBR2cAycuwg58bZ-J8zomkeUTywwizjrVGv6k5uZpqiF7iWiYkZOot05ba6Nuch79TKeb5D66dxuXF86ihkcJKqbKyx9tCXMoQo",
            },
          }
        );

        this.setMessage(response.data.message || "Reminder set successfully!", "success");
        this.reminderEmail = "";
        this.acceptTerms = false;
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          this.setMessage(error.response.data.message, "error");
        } else {
          this.setMessage("Failed to set reminder. Please try again later.", "error");
        }
      }
    },
    setMessage(msg, type) {
      this.message = msg;
      this.messageType = type;

      // Clear the message after 5 seconds
      setTimeout(() => {
        this.message = "";
      }, 5000);
    },
    // async logUserLocation() {
    //   try {
    //     const response = await axios.get("https://ipapi.co/json/");
    //     const location = response.data;
    //     console.log(`User's location: ${location.city}, ${location.region}, ${location.country_name}`);
    //   } catch (error) {
    //     console.error("Failed to fetch user location:", error);
    //   }
    // },
  },
};
</script>

<style scoped>
a {
  text-decoration: underline;
}

.link {
  text-decoration: underline !important;
}

.loading-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100vh; Full viewport height */
  padding: 2rem 0 0;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #ffffff;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-indicator p {
  margin-top: 1em;
  color: #ffffff;
}
</style>

<style>
.form {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
}

/* Transition styles */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Message box styling */
.message-box {
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
}

.error-box {
  background-color: rgba(255, 0, 0, 0.1);
  color: red;
  border: 1px solid red;
}

.success-box {
  background-color: rgba(0, 255, 0, 0.1);
  color: green;
  border: 1px solid green;
}

.submit-button.disabled {
  opacity: 0.5;
}

.text-field {
  margin-bottom: 0px;
  padding-top: 0.625em;
  padding-right: 0.625em;
  padding-bottom: 0.625em;
  padding-left: 0.625em;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--color--black);
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: var(--color--black);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--color--black);
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--color--black);
  border-top-left-radius: 0.375em;
  border-top-right-radius: 0.375em;
  border-bottom-left-radius: 0.375em;
  border-bottom-right-radius: 0.375em;
  background-color: var(--color--white);
  font-family: Homevideo Blg 6 G;
  font-size: 1.25em;
}

.text-field:placeholder {
  color: var(--color--disabled);
}

.text-field:focus-visible {
  outline: 1px solid rgb(125 225 0);
  border-radius: 3px;
}

.submit-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 11em;
  padding-top: 0.625em;
  padding-right: 1.625em;
  padding-bottom: 0.625em;
  padding-left: 1.625em;
  border-top-left-radius: 0.375em;
  border-top-right-radius: 0.375em;
  border-bottom-left-radius: 0.375em;
  border-bottom-right-radius: 0.375em;
  background-color: var(--color--white);
  font-family: Homevideo Blg 6 G;
  color: var(--color--black);
  text-wrap: nowrap;
}

.submit-button.disabled {
  opacity: 0.5;
}

.checkbox-field {
  position: relative;
  display: flex;
  margin-bottom: 0px;
  padding-right: 0em;
  padding-left: 0em;
}

.checkbox:redirected-checked {
  margin-top: 0px;
  margin-left: 0px;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-top-left-radius: 0.375em;
  border-top-right-radius: 0.375em;
  border-bottom-left-radius: 0.375em;
  border-bottom-right-radius: 0.375em;
  background-color: rgb(125 225 0);
  background-image: none;
}

.checkbox:redirected-focus-visible {
  margin-top: 0px;
  margin-left: 0px;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
}

.checkbox:redirected-focus {
  margin-top: 0px;
  margin-left: 0px;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-top-left-radius: 0.375em;
  border-top-right-radius: 0.375em;
  border-bottom-left-radius: 0.375em;
  border-bottom-right-radius: 0.375em;
  box-shadow: none;
}

.checkbox:focus-visible {
  outline: 1px solid rgb(125 225 0);
  border-radius: 3px;
}

input[type="checkbox"] {
  accent-color: rgb(125 225 0);
}

.checkbox-label {
  position: relative;
  font-family: Homevideo Blg 6 G;
  color: var(--color--white);
}

/* .checkbox-label.padding-left-xsmall {} */

.video-responsive {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* Aspect Ratio 16:9 */
  height: 0;
}

.video-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
