<template>
  <HeaderComponent :title="'Unsubscribe'" :bg-image="bgImage" />
  <main class="main">
    <section class="section">
      <div class="container padding-global-margins padding-top-huge padding-bottom-xlarge">
        <div class="flex-row padding-bottom-medium">
          <div class="_12-columns padding-bottom-small">
            <p class="game-details" v-if="games.length > 0">
              You are about to unsubscribe the email address {{ email }} from the following game release reminders:
            </p>
            <p class="game-details" v-else>
              It looks like there are no subscriptions associated with the email {{ email }}.
            </p>
            <div class="spacer-medium" v-if="games.length > 0"></div>
            <ul class="list" v-if="games.length > 0">
              <li class="list-item" v-for="game in games" :key="game._id">
                <a :href="`/game/${game.slug}`">{{ game.name }}</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="flex-row">
          <div class="auto-column">
            <form style="color: #fff" v-if="games.length > 0" @submit.prevent="confirmUnsubscribe">
              <button class="submit-button" type="submit">Unsubscribe now</button>
            </form>
          </div>
        </div>
        <div class="flex-row padding-bottom-xlarge">
          <div class="auto-column">
            <router-link class="cancel-text" to="/" style="color: #fff">Cancel and go back to calendar</router-link>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import HeaderComponent from "../components/Header.vue";
import bgImage from "@/assets/images/unsubscribe-bf1.webp";
import axios from "axios";
import { API_BASE_URL } from "../api/config";

export default {
  components: {
    HeaderComponent,
  },
  data() {
    return {
      email: "",
      games: [],
      bgImage,
    };
  },
  mounted() {
    this.email = this.$route.query.email;
    if (!this.email) {
      this.$router.push("/"); // Redirect to home if no email provided
    } else {
      this.fetchGames();
    }
  },
  methods: {
    async fetchGames() {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/unsubscribe?email=${encodeURIComponent(this.email)}`);
        this.games = response.data.games;
      } catch (error) {
        console.error("Error fetching games:", error);
        alert("Failed to fetch subscriptions. Please try again later.");
      }
    },
    async confirmUnsubscribe() {
      try {
        await axios.post(`${API_BASE_URL}/api/unsubscribe/confirm`, { email: this.email });
        this.$router.push("/unsubscribed");
      } catch (error) {
        console.error("Error unsubscribing:", error);
        alert("Failed to unsubscribe. Please try again later.");
      }
    },
  },
};
</script>

<style>
.list {
  padding-left: 0.875em;
  font-family: Homevideo Blg 6 G;
  color: var(--color--white);
  font-size: 1.5em;
}

.list-item a {
  text-decoration: underline;
}

a.cancel-text {
  text-decoration: none;
}

.submit-button {
  padding-top: 0.625em;
  padding-right: 0.625em;
  padding-bottom: 0.625em;
  padding-left: 0.625em;
  border-top-left-radius: 0.375em;
  border-top-right-radius: 0.375em;
  border-bottom-left-radius: 0.375em;
  border-bottom-right-radius: 0.375em;
  background-color: var(--color--white);
  font-family: Homevideo Blg 6 G;
  color: var(--color--black);
}
.submit-button:hover {
  background-color: #7de100;
}
.submit-button.disabled:hover {
  background-color: var(--color--white);
}
</style>
<style scoped>
/* .submit-button:hover {
  background-color: rgb(195, 255, 120);
} */
</style>
