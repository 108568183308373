<!-- components/GameDetails.vue -->
<template>
  <HeaderComponent
    :title="'Terms and Conditions'"
    :subtitle="'Stick to the rules, so we play nicely together'"
    :bg-image="bgImage"
  />
  <main class="main">
    <section class="section">
      <div class="container padding-global-margins padding-top-xhuge padding-bottom-huge">
        <div class="flex-row">
          <div class="_9-columns _12-mobile-portrait-columns">
            <h2 class="padding-bottom-xsmall">Introduction</h2>
            <p class="paragraph">
              Welcome to New Game Day ("we," "our," or "us"). These Terms and Conditions ("Terms") govern your use of
              our website and services, including but not limited to any content, functionality, and services offered on
              or through the website.<br />
              By accessing or using our website, you agree to be bound by these Terms. If you do not agree to these
              Terms, you may not use our services.
            </p>
            <h2 class="padding-top-medium padding-bottom-xsmall">Use of the Service</h2>
            <p class="paragraph">
              New Game Day provides users with information on upcoming game releases, including the ability to receive
              email reminders for specific game releases. By using this service, you agree that all information you
              provide is accurate and up-to-date.
            </p>
            <h2 class="padding-top-medium padding-bottom-xsmall">Email Collection and Use</h2>
            <p class="paragraph">
              When you enter your email address into our form on a specific game page, your email will be stored in our
              database along with the corresponding game information. This allows us to send you a reminder email on or
              before the game's release date.<br />
              By submitting your email address, you also consent to being subscribed to our monthly newsletter. The
              newsletter contains information about upcoming game releases, special offers, and other related content.
              You may opt-out of the newsletter at any time by clicking the "unsubscribe" link in the footer of any
              email you receive from us. We may create an account for you using your email address. We will inform you
              if we do so and you have the right to delete your account or request the deletion of your account.
            </p>
            <h2 class="padding-top-medium padding-bottom-xsmall">Data Privacy and Security</h2>
            <p class="paragraph">
              We take your privacy seriously and are committed to protecting your personal information. We use
              industry-standard security measures to safeguard your data. However, no method of transmission over the
              internet or electronic storage is 100% secure, so we cannot guarantee absolute security.
            </p>
            <h2 class="padding-top-medium padding-bottom-xsmall">Use of Third-Party Analytics</h2>
            <p class="paragraph">
              We use third-party analytics tools, such as Google Analytics, to collect and analyze information about how
              you use our website. These tools use cookies and other tracking technologies to help us understand user
              behavior, improve our services, and provide you with a better experience.<br />By using our website, you
              consent to the processing of your data by these third-party analytics tools in accordance with their
              respective privacy policies.
            </p>
            <h2 class="padding-top-medium padding-bottom-xsmall">User Responsibilities</h2>
            <p class="paragraph">
              As a user of our website, you agree not to use the service for any illegal or unauthorized purpose. You
              are responsible for ensuring that your use of the service complies with all applicable laws and
              regulations.
            </p>
            <h2 class="padding-top-medium padding-bottom-xsmall">Limitation of Liability</h2>
            <p class="paragraph">
              New Game Day is not responsible for any damages or losses arising from your use of our website or
              services, including but not limited to direct, indirect, incidental, punitive, and consequential
              damages.<br />
              This limitation of liability applies to any claim arising out of or in connection with these Terms or your
              use of the website.
            </p>
            <h2 class="padding-top-medium padding-bottom-xsmall">Changes to the Terms</h2>
            <p class="paragraph">
              We reserve the right to update or modify these Terms at any time. Any changes will be effective
              immediately upon posting to our website. Your continued use of the website after any changes indicates
              your acceptance of the new Terms.
            </p>
            <h2 class="padding-top-medium padding-bottom-xsmall">Contact Us</h2>
            <p class="paragraph">
              If you have any questions or concerns about these Terms, please contact us at mail[at]newgameday[dot]com.
            </p>
          </div>
        </div>
      </div>
    </section>
    <div class="bottom-navbar w-nav">
      <a :href="backLink" class="nav-link-block w-inline-block">
        <div>&lt;&lt; Back to Calendar</div>
      </a>
    </div>
  </main>
</template>

<script>
import HeaderComponent from "../components/Header.vue";
import bgImage from "@/assets/images/ngd-terms-bg-image.webp";

export default {
  name: "NgdTerms",
  components: {
    HeaderComponent,
  },
  data() {
    return {
      bgImage,
    };
  },
  computed: {
    backLink() {
      // Retrieve the last calendar page URL from localStorage
      const lastCalendarPage = localStorage.getItem("lastCalendarPage");

      // Fallback to the default calendar page if no URL was stored
      return lastCalendarPage || "/";
    },
  },
};
</script>
