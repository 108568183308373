<template>
  <div v-if="$route.name === 'GameDetails' || $route.name === 'NgdTerms'" class="navbar w-nav">
    <a :href="backLink" class="nav-link-block w-inline-block">
      <div>&lt;&lt; Back to Calendar</div>
    </a>
  </div>
  <header class="header">
    <section class="section">
      <div class="container header">
        <div class="flex-row relative-layer z-index-1">
          <div class="_10-columns _12-tablet-columns">
            <h1>{{ title }}</h1>
            <div class="spacer-custom-5"></div>
            <p class="header-subtitle-2">{{ subtitle }}</p>
          </div>
        </div>
        <div class="background-image-wrapper">
          <div class="bg-img-overlay"></div>
          <img :src="bgImage" alt="" sizes="100vw" class="background-image" />
        </div>
      </div>
    </section>
  </header>
</template>

<script>
export default {
  name: "HeaderComponent",
  props: {
    title: String,
    subtitle: String,
    bgImage: String,
  },
  computed: {
    backLink() {
      // Retrieve the last calendar page URL from localStorage
      const lastCalendarPage = localStorage.getItem("lastCalendarPage");

      // Fallback to the default calendar page if no URL was stored
      return lastCalendarPage || "/";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
/* a {
  color: #42b983;
} */
</style>
