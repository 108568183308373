<template>
  <HeaderComponent :title="'Unsubscribed'" :bg-image="bgImage" />
  <main class="main">
    <section class="section">
      <div class="container padding-global-margins padding-top-huge padding-bottom-xlarge">
        <div class="flex-row padding-bottom-medium">
          <div class="_12-columns padding-bottom-small">
            <p class="game-details">You have been unsubscribed from all game release reminders.</p>
          </div>
        </div>

        <div class="flex-row padding-bottom-xlarge">
          <div class="auto-column">
            <router-link class="cancel-text" to="/" style="color: #fff">Go back to calendar</router-link>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import HeaderComponent from "../components/Header.vue";
import bgImage from "@/assets/images/unsubscribe-bf1.webp";

export default {
  components: {
    HeaderComponent,
  },
  data() {
    return {
      bgImage,
    };
  },
  name: "UnsubscribeConfirmation",
};
</script>
